import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// Style Imports
import "./homeHero.scss"

function HomeHero(){

    return(
        <div className="home-hero">
            <div className="container">
                <h1>Bringing lupus nephritis out of the shadows starts&nbsp;with a community of support</h1>
            </div>
            <div className="container container--hero-image">
                <StaticImage className="home-hero__image--desktop" src="../../images/home-hero--Desktop.png" quality="85" layout="fixed" width={2114} alt=""></StaticImage>
                <StaticImage className="home-hero__image--mobile" src="../../images/home-hero--Mobile.png" quality="100" height={131} layout="fixed" alt=""></StaticImage>
            </div>
        </div>
    )
}

export default HomeHero
