import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
// Style Imports
import "./portrait.scss"

function Portrait(props){
    return (
        <div className="portrait">
            <div className="text-block">
                <h2 className="portrait__name">
                    {props.name}
                </h2>
                <p className="portrait__description">{props.description}</p>
            </div>
            <GatsbyImage className="portrait__image" loading="eager" placeholder="none" critical="true" image={props.imgSrc} quality="90" layout="fixed" alt={props.imgAlt}></GatsbyImage>
        </div>
      )
}

export default Portrait
